import React from "react"
import { useStaticQuery } from "gatsby"
import get from "lodash/get"
import { Link, Element, animateScroll as scroll } from "react-scroll"
import { Waypoint } from "react-waypoint"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Img from "gatsby-image"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      positionName: "",
      pathName: null,
      mobile: false,
    }
    this.windowHeight = 0
    this.handleWaypointEnter = this.handleWaypointEnter.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.checkPath = this.checkPath.bind(this)
  }

  componentDidMount() {
    this.checkPath()
    window.addEventListener("resize", this.handleResize)
    this.setState({
      mobile: window.innerWidth <= 768,
    })
    this.handleResize()
  }
  handleResize() {
    this.windowHeight = window.innerHeight
    this.setState({
      mobile: window.innerWidth <= 768,
    })
  }
  handleWaypointEnter(name) {
    this.setState({
      positionName: name,
    })
  }

  checkPath() {
    const search = this.props.location.search
    if (search) {
      const client = search.split("?")[1]
      this.setState({ pathName: client })
    }
  }

  render() {
    let page = get(this, "props.data.allContentfulPage.edges")
    page = page.map(i => i.node)
    if (!page) {
      return ""
    }
    const pageTitle = page[0].pageTitle
    const posts = page[0].clients
    let filteredPosts = posts
    if (this.state.pathName !== null) {
      filteredPosts = filteredPosts.filter(i => i.slug === this.state.pathName)
    }
    const display = filteredPosts.length > 0 ? filteredPosts : posts

    const options = {
      renderNode: {
        [INLINES.HYPERLINK]: node => {
          const title = node.content[0].value
          const link = node.data.uri
          return (
            <a target="_blank" rel="noopener noreferrer" href={link}>
              {title}
            </a>
          )
        },
      },
    }

    let hero_video = this.state.mobile ? 426073858 : 365561155
    let previews = posts.map((itm, idx) => {
      let thumbnail = itm.thumbnail.fluid
      let offset = this.windowHeight - 20 + "px"
      return (
        <Col xs="4" key={idx} className="position-relative preview-img-tile">
          <Waypoint
            onEnter={() => this.handleWaypointEnter("")}
            bottomOffset={offset}
          />
          {filteredPosts.length === 1 ? (
            <a href={`https://www.part02.com/?${itm.slug}`}>
              <Img fluid={thumbnail}></Img>

              <h1>{itm.clientName}</h1>
              <div className="transparent-layer" />
            </a>
          ) : (
            <Link
              to={itm.clientName}
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <Img fluid={thumbnail}></Img>

              <h1>{itm.clientName}</h1>
              <div className="transparent-layer" />
            </Link>
          )}
        </Col>
      )
    })

    const fullPost = display.map((i, idx) => {
      if (!i.clientModules) {
        return ""
      }
      let clientModules = i.clientModules.map((m, idx) => {
        if (m.__typename === "ContentfulHeroImageModule") {
          if (m.heroImage) {
            let ext = m.heroImage.file.url.split(".").pop()
            if (ext === "gif") {
              return <img src={m.heroImage.file.url} alt="hero" />
            } else {
              return <Img key={idx} fluid={m.heroImage.fluid}></Img>
            }
          } else return ""
        } else if (m.__typename === "ContentfulTwoImageModule") {
          let leftImg_ext = m.leftImage && m.leftImage.file.url.split(".").pop()
          let rightImg_ext =
            m.rightImage && m.rightImage.file.url.split(".").pop()
          return (
            <Row key={idx}>
              <Col xs="6" className="px-0">
                {m.leftImage &&
                  (leftImg_ext === "gif" ? (
                    <img src={m.leftImage.file.url} alt="side by left" />
                  ) : (
                    <Img fluid={m.leftImage.fluid}></Img>
                  ))}
              </Col>
              <Col xs="6" className="px-0">
                {m.rightImage &&
                  (rightImg_ext === "gif" ? (
                    <img src={m.rightImage.file.url} alt="side by right" />
                  ) : (
                    <Img fluid={m.rightImage.fluid}></Img>
                  ))}
              </Col>
            </Row>
          )
        } else if (m.__typename === "ContentfulThreeImageModule") {
          let { orientLeft, mainImage, bottomImage, topImage } = m
          let m_ext = mainImage && mainImage.file.url.split(".").pop()
          let t_ext = topImage && topImage.file.url.split(".").pop()
          let b_ext = bottomImage && bottomImage.file.url.split(".").pop()

          let main = (
            <Col xs="6" className="px-0">
              {mainImage &&
                (m_ext === "gif" ? (
                  <img src={mainImage.file.url} alt="main" />
                ) : (
                  <Img fluid={mainImage.fluid}></Img>
                ))}
            </Col>
          )

          return (
            <Row key={idx}>
              {orientLeft && main}
              <Col xs="6" className="px-0">
                <Row>
                  <Col xs="12" className="px-0">
                    {topImage &&
                      (t_ext === "gif" ? (
                        <img src={topImage.file.url} alt="main" />
                      ) : (
                        <Img fluid={topImage.fluid}></Img>
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="px-0">
                    {bottomImage &&
                      (b_ext === "gif" ? (
                        <img src={bottomImage.file.url} alt="main" />
                      ) : (
                        <Img fluid={bottomImage.fluid}></Img>
                      ))}
                  </Col>
                </Row>
              </Col>
              {!orientLeft && main}
            </Row>
          )
        } else if (m.__typename === "ContentfulFourImageModule") {
          let tl_ext =
            m.topLeftImage && m.topLeftImage.file.url.split(".").pop()

          let tr_ext =
            m.topRightImage && m.topRightImage.file.url.split(".").pop()

          let br_ext =
            m.bottomRightImage && m.bottomRightImage.file.url.split(".").pop()

          let bl_ext =
            m.bottomLeftImage && m.bottomLeftImage.file.url.split(".").pop()

          return (
            <Row key={idx}>
              <Col>
                <Row>
                  <Col xs="6" className="px-0">
                    {m.topLeftImage &&
                      (tl_ext === "gif" ? (
                        <img src={m.topLeftImage.file.url} alt="top left" />
                      ) : (
                        <Img fluid={m.topLeftImage.fluid}></Img>
                      ))}
                  </Col>
                  <Col xs="6" className="px-0">
                    {m.topRightImage &&
                      (tr_ext === "gif" ? (
                        <img src={m.topRightImage.file.url} alt="top right" />
                      ) : (
                        <Img key={idx} fluid={m.topRightImage.fluid}></Img>
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" className="px-0">
                    {m.bottomLeftImage &&
                      (bl_ext === "gif" ? (
                        <img
                          src={m.bottomLeftImage.file.url}
                          alt="bottom left"
                        />
                      ) : (
                        <Img key={idx} fluid={m.bottomLeftImage.fluid}></Img>
                      ))}
                  </Col>
                  <Col xs="6" className="px-0">
                    {m.bottomRightImage &&
                      (br_ext === "gif" ? (
                        <img
                          src={m.bottomRightImage.file.url}
                          alt="bottom right"
                        />
                      ) : (
                        <Img key={idx} fluid={m.bottomRightImage.fluid}></Img>
                      ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        } else if (m.__typename === "ContentfulVideoModule") {
          return (
            <div key={idx} className="embed-container">
              <a href={m.videoUrl} target="_blank" rel="noopener noreferrer">
                <iframe
                  key={idx}
                  title="part02"
                  src={
                    m.videoUrl +
                    "?autoplay=1&loop=1&autopause=0&muted=1&background=1"
                  }
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </a>
            </div>
          )
        } else {
          return ""
        }
      })
      let offset = this.windowHeight - 20 + "px"
      // add desc here
      let json = i.childContentfulClientClientDescriptionRichTextNode.json

      return (
        <Col key={idx}>
          <Waypoint
            onEnter={() => this.handleWaypointEnter(i.clientName)}
            bottomOffset={offset}
          >
            <div>
              <Element name={i.clientName} />
              {clientModules}
              <Row className="d-flex py-5 body-padding bg-white">
                <Col xs="12" md="6">
                  <h1>{i.clientName}</h1>
                </Col>
                <Col xs="12" md="6" className="pl-md-0">
                  <div className="client-description">
                    {documentToReactComponents(json, options)}
                  </div>
                </Col>
              </Row>
            </div>
          </Waypoint>
        </Col>
      )
    })

    return (
      <Layout name={this.state.positionName}>
        <SEO title="CD + AD" />
        <Wrapper>
          <Element name="top" />
          <Container fluid>
            {filteredPosts.length !== 1 && (
              <>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="embed-container hero-video">
                      <Link
                        to="top-preview"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                      >
                        <iframe
                          title="part02"
                          src={`https://player.vimeo.com/video/${hero_video}?autoplay=1&loop=1&autopause=0&muted=1&background=1`}
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          allowFullScreen
                        ></iframe>
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Element name="top-preview" />
                  {previews}
                </Row>
              </>
            )}

            <Row className="d-flex flex-column">{fullPost}</Row>
            <Row>{previews}</Row>
          </Container>
          {filteredPosts.length === 1 && (
            <TopButton>
              <a href="/"> VIEW ALL</a>
            </TopButton>
          )}
          {this.state.positionName !== "" && filteredPosts.length !== 1 && (
            <TopButton>
              <Link
                to="top-preview"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                TOP
              </Link>
            </TopButton>
          )}
        </Wrapper>
      </Layout>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const TopButton = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 100000;
`

export default IndexPage

export const pageQuery = graphql`
  query pageQuery {
    allContentfulPage {
      edges {
        node {
          pageTitle
          clients {
            clientName
            slug
            childContentfulClientClientDescriptionRichTextNode {
              json
            }
            thumbnail {
              title
              description
              file {
                url
                contentType
              }
              fluid(maxWidth: 3080) {
                src
                ...GatsbyContentfulFluid_noBase64
              }
            }
            clientModules {
              ... on ContentfulVideoModule {
                videoUrl
              }
              ... on ContentfulTwoImageModule {
                leftImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
                rightImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
              }
              ... on ContentfulHeroImageModule {
                heroImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
              }
              ... on ContentfulThreeImageModule {
                id
                mainImage {
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                  file {
                    url
                  }
                }
                bottomImage {
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                  file {
                    url
                  }
                }
                topImage {
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                  file {
                    url
                  }
                }
                orientLeft
              }
              ... on ContentfulFourImageModule {
                bottomLeftImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
                bottomRightImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
                topLeftImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
                topRightImage {
                  file {
                    url
                  }
                  fluid(maxWidth: 3080, quality: 100) {
                    src
                    ...GatsbyContentfulFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
